import './App.css';
import NavBar from './components/NavBar/NavBar';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './pages/Contact';
import Footer from './components/Footer/Footer';
import About from './pages/About';
import Services from './pages/Services';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


function App() {
  return (
    <div className="App">
      <NavBar />
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="contact" element={<Contact />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="/:service" element={<Services />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <section class="whats-app-btn">
        <a href="https://api.whatsapp.com/send?phone=+918946021376&text=Hi%20Sir,%20I'm%20Looking%20for%20more%20details%20about%20G-Square%20OMR%20Properties"
          class="float" target="_blank">

          <WhatsAppIcon className="whatsapp-btn" />

        </a>
      </section>

    </div>
  );
}

export default App;
