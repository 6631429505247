import React, { useState } from "react";
import "./Services.css";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import ServicesArray from "../components/NavBar/ServicesArray";
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet";

function About() {

    return (
        <>

            <Helmet>
                <title>About</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>


            <div className="service row container-fluid">
                <div className="col-md-8 p-0 pt-md-2 p-md-5">

                    <div id="service-content" class="container-fluid w-100 ">
                        <h2>About</h2>
                        <p>In addition to content, personalization, subscriptions, and customer insights are vital. Our goal is to guide media and entertainment companies toward achieving transformation through enablers and strategies.
                            Challenges impacting growth include increasing customer expectations, sophisticated buyers, and increased competition. We help companies differentiate their service at all customer lifecycle stages by facilitating seamless omnichannel interactions with a digital-first agenda. With our solution, your customers stay for longer, and you are equipped with the tools you need to grow revenue from your most profitable customers.</p>
                    </div>

                </div>

            </div>


        </>
    )
}

export default About;