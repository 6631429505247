import React, { useState } from "react";
import "./Services.css";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import ServicesArray from "../components/NavBar/ServicesArray";
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet";

const validationSchema = yup.object({
    name: yup
        .string("Enter your name")
        .matches(/^[aA-zZ\s]+$/, "Enter a valid name")
        .required("Name is required"),
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    businessName: yup
        .string("Enter your business name")
        .matches(/^[aA-zZ\s]+$/, "Enter a valid business name")
        .required("Business Name is required"),
    description: yup.string("Enter your description"),
    personName: yup
        .string("Select your category"),

});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'CRM - Real Estate',
    'Website Development',
    'Mobile App Development',
    'Digital Marketing',
    'Social Media Marketing',
    'Apartment Marketing',
    'Flat Marketing',
    'Villa Marketing',
    'Plots Marketing',
    'Influencer Marketing',
    'Search Engine Optimization',
    'Google Ads Marketing',
];

function Services() {

    const id = useParams();

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const [submited, setSubmited] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            businessName: "",
            description: "",
            personName: "",

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmited(true);
            setInterval(() => {
                setSubmited(false);
            }, 5000);
        },
    });

    return (
        <>
            {
                ServicesArray.map((service) => {
                    if (service.link.slice(1) === id.service) {
                        return (
                            <>
                                <Helmet>
                                    <title>{service.heading}</title>
                                    <meta name="description" content="Web site created using create-react-app" />
                                </Helmet>
                            </>
                        )
                    }
                })
            }


            <div className="service row container-fluid">
                <div className="col-md-8 p-0 pt-md-2 p-md-5">

                    {
                        ServicesArray.map((service) => {
                            if (service.link.slice(1) === id.service) {
                                return (
                                    <>
                                        <div id="service-content" class="container-fluid w-100 ">
                                            <h2>{service.heading}</h2>
                                            <p>In addition to content, personalization, subscriptions, and customer insights are vital. Our goal is to guide media and entertainment companies toward achieving transformation through enablers and strategies.
                                                Challenges impacting growth include increasing customer expectations, sophisticated buyers, and increased competition. We help companies differentiate their service at all customer lifecycle stages by facilitating seamless omnichannel interactions with a digital-first agenda. With our solution, your customers stay for longer, and you are equipped with the tools you need to grow revenue from your most profitable customers.</p>
                                        </div>
                                    </>
                                )
                            }
                        })
                    }

                </div>
                <div className="col-md-4 p-0 pt-md-5 p-md-5 ">

                    <div id="service-form" class="container-fluid w-100 w-lg-75 p-5">

                        <form class="row" onSubmit={formik.handleSubmit}>

                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                className="mb-2"
                            />

                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                className="mb-4"
                            />

                            <MuiPhoneNumber
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                type="phone"
                                fullWidth
                                defaultCountry={"in"}
                                value={formik.values.phone}
                                onChange={(value) => formik.setFieldValue("phone", value)}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                className="mb-2"
                            />


                            <TextField
                                fullWidth
                                id="business name"
                                name="businessName"
                                label="Business Name"
                                type="text"
                                value={formik.values.businessName}
                                onChange={formik.handleChange}
                                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                                helperText={formik.touched.businessName && formik.errors.businessName}
                                className="mb-4"
                            />

                            <button className="submit-btn" type="submit">
                                SUBMIT
                            </button>
                        </form>
                    </div>
                    {submited ? (
                        <p className="sms-sent">message sent successfully !</p>
                    ) : (
                        ""
                    )}
                </div>
            </div>

        </>
    )
}

export default Services;