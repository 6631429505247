import React from "react";
import "./Home.css"
import { Helmet } from "react-helmet";

function Home() {
    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>

            <div className="home">
                <section id="section1">
                    <div className="row container-fluid">
                        <div className="col-md-8 p-2 p-md-0">
                            <h1>Real Estate Digital Marketing Company</h1>
                            <h6>Encompasses Technology and Growth Marketing Solutions to the Realty Firms in India</h6>
                            <br></br>
                            <br></br>
                            <a href="#" class="box">
                                Learn More
                            </a>
                        </div>
                        <div className="col-md-4 pt-5 p-md-0">
                            <img className="img-fluid" src="/images/bg/bg-3.png"></img>
                        </div>
                    </div>
                </section>
                <section id="section2">
                    <div>
                        <h1>What we do?</h1>
                    </div>
                    <div className="row card-container">
                        <div className="col-md-4 p-4 ">
                            <div className="col-12 p-4 outer-container">
                                <div className="col-12 p-4 inner-container">
                                    <div className="icon-container">
                                        <img src="/images/s2/crm.png"></img>
                                    </div>
                                    <div className="content-contianer">
                                        <h3>CRM for Commercial Real Estate </h3>
                                        <p>
                                        Use our in-built CRM software to manage, track and interact with the potential clients,  prospects of your Real Estate Business. This Commercial Real Estate CRM is a one stop solution for all the realtors to manage and grow their Business effectively in a single dashboard.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-4 ">
                            <div className="col-12 p-4 outer-container">
                                <div className="col-12 p-4 inner-container">
                                    <div className="icon-container">
                                        <img src="/images/s2/web-design.png"></img>
                                    </div>
                                    <div className="content-contianer">
                                        <h3>Website/Mobile App Development</h3>
                                        <p>
                                        We create and build beautiful websites and mobile applications for realtors companies to enhance and establish their Digital Identity. We deal with various companies to develop Web & Mobile apps for their Physical Real Estate Properties to Virtual Real Estate Lands (Metaverse Lands).  

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-4 ">
                            <div className="col-12 p-4 outer-container">
                                <div className="col-12 p-4 inner-container">
                                    <div className="icon-container">
                                        <img src="/images/s2/social-media (1).png"></img>
                                    </div>
                                    <div className="content-contianer">
                                        <h3>Digital Marketing Consultation</h3>
                                        <p>
                                        Being a niche Real Estate Digital Marketing company, we have experienced working with various clients for their reach establishment in online channels to sales conversion. Regular way of Digital marketing doesn’t help you to stand out from your competitor’s promotional activities. Out of the box strategies work well. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section3">
                    <div className="row m-0 container-fluid">
                        <div className="col-md-7 container-fluid d-flex flex-column justify-content-center align-items-center">
                            <h1>Micro-site For Each Property/Projects:
</h1>
                            <p> Best way of marketing the new Real Estate Projects of your company is by creating a separate website for each property or project.</p>
                        </div>
                        <div className="col-md-5 container-fluid d-flex flex-column justify-content-center align-items-center">
                            <img className="img-fluid" src="/images/zone_screen.png" alt="zone_screen"></img>
                        </div>
                    </div>
                </section>

                <section id="section4">
                    <div className="row m-0 container-fluid">
                        <div className="col-md-5 container-fluid d-flex flex-column justify-content-center align-items-center">
                            <img className="img-fluid" src="/images/zone_screen.png" alt="zone_screen"></img>
                        </div>
                        <div className="col-md-7 container-fluid d-flex flex-column justify-content-center align-items-center">
                            <h1>Generate Leads using Funnels:</h1>
                            <p> Whether it is a Video marketing campaign or Display marketing campaign or Search campaign. Generating the Sales Qualified Leads worth making it. </p>
                        </div>
                    </div>
                </section>

                <section id="section5">
                    <h1>Huge pack of elements</h1>
                    <div className="row m-0 container-fluid card-container">
                        <div className="col-lg-4 p-md-4 p-0 pb-3 container-fluid">
                            <div className="col-12 card">
                                <p>LICENSE</p>
                                <h4>Standard</h4>
                                <div>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                </div>

                                <ul>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <hr></hr>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                </ul>
                                <a className="card-btn" href="#">Choose Plan</a>
                            </div>
                        </div>

                        <div className="col-lg-4  p-md-4 p-0 pb-3 container-fluid">
                            <div className="col-12 card">
                                <p>LICENSE</p>
                                <h4>Standard</h4>
                                <div>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                </div>

                                <ul>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <hr></hr>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                </ul>
                                <a className="card-btn" href="#">Choose Plan</a>
                            </div>
                        </div>

                        <div className="col-lg-4 p-md-4 p-0 pb-3  container-fluid">
                            <div className="col-12 card">
                                <p>LICENSE</p>
                                <h4>Standard</h4>
                                <div>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                    <img className="heart" src="/images/card/heart.png" alt="heart"></img>
                                </div>

                                <ul>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <hr></hr>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                    <li><img className="tick-li" src="/images/footer/tick.png"></img>One end products</li>
                                </ul>
                                <a className="card-btn" href="#">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="p-5">
                        <h3>Still have questions?</h3>
                        <p>Please describe your case to receive the most accurate advice.</p>
                        <div className="d-flex justify-content-center align-items-center">
                            <a href="#">Contact us</a>
                        </div>
                    </div>
                </section>

                <section id="section6">
                    <div className="row m-0 container-fluid">
                        <div className="col-md-12 container-fluid d-flex flex-column justify-content-center align-items-center">
                            <h1 className="text-center">India's Best Real Estate Digital Marketing Agency:
</h1>
<ol>
<li>Get Traffic to your website.</li>
<li>Engage your audiences in Social Media. </li>
<li>Promote in Google Ads using Best Real Estate Marketing Strategy.</li>
<li>Make your Property reach the Right audience and increase Brand awareness. 
</li>
<li>Invest in SEO. Don’t miss the organic gold (lead).
</li>
<li>Content Marketing Strategies for your Real Estate Business.
</li>
<li>Video Creation for YouTube & Instagram. 
</li>
<li>Email Marketing to tap the target audiences.</li>
<li>SMS & WhatsApp Marketing using Automation Software tools.
</li>
<li>In-depth Analytics of your Website & Social Media Platforms.</li>
<li>
Performance Reports. </li>
</ol> 
                          

                        </div>
                        
                    </div>

                   
                </section>

            </div>

        </>
    )
}

export default Home;