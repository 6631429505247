import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { Outlet, Link } from "react-router-dom";
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ServicesArray from "./ServicesArray";
import { Helmet } from "react-helmet";


function NavBar() {

    const [open, setOpen] = useState(true)
    const [dropDown, setDropDown] = useState(false)
    const [active, setActive] = useState(false)
    const [mbDropDown, setMbDropDown] = useState(false)

    function sideNavOpen() {
        setOpen(!open)
        setMbDropDown(true)
    }
    function dropDownOpen() {
        setDropDown(!dropDown)
    }
    function menuActive() {
        setActive(true)
    }



    return (
        <>
            <div className="navbar">
                <div className="nav">
                    <div>
                        <a href="/" className="logo"><img src="/images/Zeekable-logo.png" alt="zeek-logo"></img></a>
                    </div>
                    <div className={open ? "nav-menus hidden" : "nav-menus"}>
                        <a href="/" className="menu active" onClick={menuActive}>Home</a>
                        <a href="about" className="menu">About</a>
                        <a href="#" className="menu" onClick={dropDownOpen}>Services
                            {
                                dropDown ? <KeyboardArrowUpIcon color="white" /> : <KeyboardArrowDownSharpIcon color="white" />
                            }
                        </a>
                        {
                            dropDown ? (
                                <>
                                    <div className={dropDown ? "swathi" : "hide"}>
                                        <div className="container-fluid p-md-2 p-0">
                                            <h6>Real Estate Technology Services</h6>
                                            <ul>
                                                {
                                                    ServicesArray.map((service) => {
                                                        return (
                                                            <li>
                                                                <Link to={service.link}>{service.heading}</Link>
                                                           </li>
                                                        )
                                                    })
                                                }
                                                <Outlet />
                                            </ul>
                                        </div>
                                        <div className="container-fluid ">
                                            <img className="img-fluid" src="/images/drop-down-img.png" alt="drop-down-img"></img>
                                        </div>
                                    </div>
                                </>
                            ) : ""
                        }



                        <a id="menu" href="contact" className="menu">Contact</a>
                        <a href="#" className="menu enquire-now-nav">Enquire Now</a>

                    </div>
                    <div className="side-nav">
                        <a href="#" onClick={sideNavOpen}>
                            {
                                open ? <MenuIcon color="white" /> : <CloseIcon className="close-menu" />
                            }

                        </a>
                    </div>
                </div>
            </div>


        </>
    )

}

export default NavBar;