import React, { useState } from "react";
import "./Contact.css";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Helmet } from "react-helmet";

const validationSchema = yup.object({
    name: yup
        .string("Enter your name")
        .matches(/^[aA-zZ\s]+$/, "Enter a valid name")
        .required("Name is required"),
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    businessName: yup
        .string("Enter your business name")
        .matches(/^[aA-zZ\s]+$/, "Enter a valid business name")
        .required("Business Name is required"),
    description: yup.string("Enter your description"),
    personName: yup
        .string("Select your category"),

});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'CRM - Real Estate',
    'Website Development',
    'Mobile App Development',
    'Digital Marketing',
    'Social Media Marketing',
    'Apartment Marketing',
    'Flat Marketing',
    'Villa Marketing',
    'Plots Marketing',
    'Influencer Marketing',
    'Search Engine Optimization',
    'Google Ads Marketing',
];

function Contact() {

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const [submited, setSubmited] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            businessName: "",
            description: "",
            personName: "",

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmited(true);
            setInterval(() => {
                setSubmited(false);
            }, 5000);
        },
    });

    return (
        <>
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>

            <div className="contact row container-fluid">
                <div className="col-md-8 p-0 pt-md-2 p-md-5">

                    <div id="address" class="container-fluid w-100 ">
                        <h1 className="huon">HUON </h1>
                        <h1 className="huon-1">Real Estate Technology Company</h1>
                        <h6><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img>No:10, 29th Cross Village High Road,<br></br> Sholinganallur, OMR,<br></br> Chennai 600119.</h6>
                        <a href="mailto:contact@huon.in?Subject=Dear%20team,%20I%20have%20an%20enquiry.%20Kindly%20get%20in%20touch%20with%20me"><img className="mail-icon" src="/images/contact/mail.png" alt="mail"></img>contact@huon.in</a>
                    </div>
                </div>
                <div className="col-md-4 p-0 pt-md-2 p-md-5 ">

                    <div id="contact-form" class="container-fluid w-100 w-lg-75 p-5">
                        <h1 className="contact-heading">Contact Us</h1>
                        <form class="row" onSubmit={formik.handleSubmit}>

                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                className="mb-2"
                            />

                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                className="mb-4"
                            />

                            <MuiPhoneNumber
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                type="phone"
                                fullWidth
                                defaultCountry={"in"}
                                value={formik.values.phone}
                                onChange={(value) => formik.setFieldValue("phone", value)}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                className="mb-2"
                            />


                            <TextField
                                fullWidth
                                id="business name"
                                name="businessName"
                                label="Business Name"
                                type="text"
                                value={formik.values.businessName}
                                onChange={formik.handleChange}
                                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                                helperText={formik.touched.businessName && formik.errors.businessName}
                                className="mb-4"
                            />
                            <FormControl>
                                <InputLabel id="demo-multiple-checkbox-label">Select your category</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple

                                    value={personName}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Select your category" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    error={formik.touched.personName && Boolean(formik.errors.personName)}
                                    helperText={formik.touched.personName && formik.errors.personName}
                                    className="mb-2"
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={personName.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                id="standard-multiline-flexible"
                                label="Description"
                                multiline
                                maxRows={4}
                                type="text"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                                className="mb-4"
                                variant="standard"
                            />
                            <button className="submit-btn" type="submit">
                                SUBMIT
                            </button>
                        </form>
                    </div>
                    {submited ? (
                        <p className="sms-sent">message sent successfully !</p>
                    ) : (
                        ""
                    )}
                </div>
            </div>

        </>
    )
}

export default Contact;