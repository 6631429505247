const ServicesArray = [
    {
        heading: "CRM - Real Estate",
        link: "/crm-real-estate"
    },
    {
        heading: "Website Development",
        link: "/website-development"
    },
    {
        heading: "Mobile App Development",
        link: "/mobile-app-development"
    },
    {
        heading: "Digital Marketing",
        link: "/digital-marketing"
    },
    {
        heading: "Social Media Marketing",
        link: "/social-media-marketing"
    },
    {
        heading: "Apartment Marketing",
        link: "/apartment-marketing"
    },
    {
        heading: "Flat Marketing",
        link: "/flat-marketing"
    },
    {
        heading: "Villa Marketing",
        link: "/villa-marketing"
    },
    {
        heading: "Plots Marketing",
        link: "/plots-marketing"
    },
    {
        heading: "Influencer Marketing",
        link: "/influencer-marketing"
    },
    {
        heading: "Search Engine Optimization",
        link: "/search-engine-optimization"
    },
    {
        heading: "Google Ads Marketing",
        link: "/google-ads"
    },

]

export default ServicesArray;