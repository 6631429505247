import React from "react";
import "./Footer.css";



function Footer() {
    return (
        <>
            <div className="footer">
                <div className="row container-fluid footer-content">
                    <div className="col-6 col-md-3 p-0 pb-4 container-fluid">
                        <h6>Cities</h6>
                        <ul>
                            {
                                /* <li>Ahmedabad</li> 
                                <li>Thrivandrum</li>
                                */
                            }

                            <li><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img> Bangalore</li>
                            <li><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img> Chennai</li>
                            <li><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img> Delhi</li>
                            <li><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img> Kolkata</li>
                            <li><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img> Mumbai</li>
                            <li><img className="location-icon" src="/images/footer/location.png" alt="location-icon"></img> Pune</li>

                        </ul>
                    </div>
                    <div className="col-6 col-md-3 p-0 pb-4 container-fluid">
                        <h6>Services</h6>
                        <ul>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> CRM - Real Estate</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Website Development</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Mobile App Development</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Digital Marketing</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Social Media Marketing</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Apartment Marketing</li>

                        </ul>
                    </div>
                    <div className="col-6 col-md-3 p-0 pb-4  container-fluid">
                        <h6>Services</h6>
                        <ul>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Flat Marketing</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Villa Marketing</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Plots Marketing</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Influencer Marketing</li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Search Engine (SEO) </li>
                            <li><img className="tick-icon" src="/images/footer/tick.png" alt="tick-icon"></img> Google Ads Marketing</li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3 p-0 pb-4  container-fluid">
                        <h6>Company</h6>
                        <ul>
                            <li><img className="about-icon" src="/images/footer/about.png" alt="aboutus"></img> About Us</li>
                            <li><img className="contact-icon" src="/images/footer/contact.png" alt="contactus"></img> Contact Us</li>
                            <li><img className="tc-icon" src="/images/footer/tc.png" alt="terms and conditions"></img> Terms & Conditions</li>
                            <li><img className="pp-icon" src="/images/footer/pp.png" alt="privacy policy"></img> Privacy Policy</li>
                            <li><img className="career-icon" src="/images/footer/career.png" alt="career"></img> Careers</li>
                            <li><img className="case-study-icon" src="/images/footer/case-study.png" alt="case-study"></img> Case Study<sup className="coming-soon">Coming Soon</sup></li>
                        </ul>
                    </div>

                </div>
                <br></br>
                <h6>Follow Us On</h6>
                <div className="icon-container">
                    <img src="/images/footer/facebook.png"></img>
                    <img src="/images/footer/twitter.png"></img>
                    <img src="/images/footer/instagram.png"></img>
                    <img src="/images/footer/linkedin.png"></img>
                </div>
                <h6>A Brand of <a href="http://zeek.digital/">Zeek Digital</a></h6>
            </div>
        </>
    )
}

export default Footer;